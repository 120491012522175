import React, { memo } from 'react';
import { useTermsAndPrivacyContent } from 'App/Game/hooks';
import config from '../../config';
import Modal from 'App/React/_UI/Modal/Modal';
import Hero from './components/Hero';
import About from './components/About';
import Features from './components/Features';
import Advantages from './components/Advantages';
import Tutorial from './components/Tutorial';
import Footer from './components/Footer';
import './styles.scss';

const Landing = ({ updatePhase, showPwaPhase, showPushPhase, gameGA, ga }) => {
    const [modalContent, isModalOpen, showTermsUse, hideTermsUse] =
        useTermsAndPrivacyContent({ modalUrl: config.modalsUrl });

    const playBtnClickHandler = () => {
        updatePhase(showPushPhase ? 'push' : showPwaPhase ? 'pwa' : 'game');
        gameGA?.sendDesignEvent('HP:PlayButton');
        ga?.basicGaEvent('hp', 'play_button');
    };

    return (
        <div className={'landing-phase'}>
            <main>
                <Hero
                    onBtnClick={playBtnClickHandler}
                    gameGA={gameGA}
                    ga={ga}
                />
                <About onBtnClick={playBtnClickHandler} />
                <Features />
                <Advantages onBtnClick={playBtnClickHandler} />
                <Tutorial onBtnClick={playBtnClickHandler} />
            </main>
            <Footer showTermsUse={showTermsUse} />
            {isModalOpen && (
                <Modal
                    title={modalContent ? modalContent.title : ''}
                    content={modalContent ? modalContent.content : ''}
                    onClose={hideTermsUse}
                />
            )}
        </div>
    );
};

export default memo(Landing);
