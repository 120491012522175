import { environment } from './enviroments';

/**
 * console info log message with possibility to add message colors
 *
 * @param message: string (required)
 * @param bgColor: string (optional)
 * @param textColor: string (optional)
 */
export const consoleInfoLog = (
    message,
    bgColor = '#F9CCC8',
    textColor = '#2C2826'
) => {
    environment() !== 'production' &&
        console.info(
            `%c${message}`,
            `background: ${bgColor}; color: ${textColor}; font-weight: 900`
        );
};
