import React from 'react';
import LazyImg from 'App/React/_UI/LazyImg';
import free1920 from '../../../../assets/free-1920.png';
import simple1920 from '../../../../assets/simple-1920.png';
import endless1920 from '../../../../assets/endless-1920.png';
import eyeCatching1920 from '../../../../assets/eye-catching-1920.png';
import './styles.scss';

const Features = () => {
    return (
        <section className={'container small'}>
            <div className={'wrapper features'}>
                <h2 className={'title'}>
                    <span className={'highlighted'}>Game </span>
                    Features
                </h2>
                <div className={'feature-items'}>
                    <div className={'features-wrapper'}>
                        <div className={'feature-item'}>
                            <LazyImg
                                url={free1920}
                                width={282}
                                height={293}
                                alt='snake free play'
                            />
                            <h3 className={'feature-title'}>Free to play</h3>
                        </div>
                        <div className={'feature-item'}>
                            <LazyImg
                                url={simple1920}
                                width={282}
                                height={293}
                                alt='simple mode snake game'
                            />
                            <h3 className={'feature-title'}>
                                Simple swipe control
                            </h3>
                        </div>
                    </div>
                    <div className={'features-wrapper'}>
                        <div className={'feature-item'}>
                            <LazyImg
                                url={endless1920}
                                width={282}
                                height={293}
                                alt='endless snake game'
                            />
                            <h3 className={'feature-title'}>
                                Endless gameplay in colorful locations
                            </h3>
                        </div>
                        <div className={'feature-item'}>
                            <LazyImg
                                url={eyeCatching1920}
                                width={282}
                                height={293}
                                alt='eye catching snake game'
                            />
                            <h3 className={'feature-title'}>
                                Eye-catching graphics and animations
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Features;
