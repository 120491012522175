import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    AppProvider,
    PushNotificationProvider,
    PwaProvider,
} from './providers';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <PushNotificationProvider>
        <PwaProvider>
            <AppProvider>
                <App />
            </AppProvider>
        </PwaProvider>
    </PushNotificationProvider>
);
