import React, {
    createContext,
    useContext,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { usePageLoad } from 'App/Game/hooks';
import SentryConfig from 'App/Helpers/SentryConfig';
import { environment } from 'App/Helpers/enviroments';
import checkAdBlockers from 'App/Helpers/checkAdBlockers';
import CookieService from 'App/Services/CookieService';
import {
    filterGameRelatedAttributes,
    getGameInitValues,
    getUrlParams,
    localStorageValueReset,
} from 'App/Game/helpers';
import { getHash } from 'App/helpers';
import config, { rewardIAdMap } from '../config';

const phaseMap = {
    1: 'landing',
    2: 'push',
    3: 'pwa',
    4: 'game',
};

const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
    const {
        sharpstarApiUrl,
        campaignId,
        gaTrackId,
        domain,
        source,
        gameAnalyticsKey,
        gameAnalyticsSecret,
        buildVersion,
    } = config;
    const [urlParams, setUrlParams] = useState({
        rid: undefined,
        refid1: undefined,
        refid2: undefined,
        refid3: undefined,
        hash: undefined,
    });
    const [phase, setPhase] = useState(''); // 'landing','push','pwa','game'
    const [adBlocked, setAdBlocked] = useState(undefined);
    const [sharpstarService, setSharpstarService] = useState(undefined);
    const [leadExtraData, setLeadExtraData] = useState(undefined);
    const [offer, setOffer] = useState(undefined);
    const [gameGA, setGameGA] = useState(undefined);
    const [ga, setGA] = useState(undefined);
    const [gameConfig, setGameConfig] = useState(undefined);
    const [adsConfig, setAdsConfig] = useState(undefined);

    const isPageLoaded = usePageLoad();

    useEffect(() => {
        handleUrlParams();

        const hashChangedHandler = () => {
            const urlHashValue = getHash();
            if (phaseMap[urlHashValue]) {
                updatePhase(phaseMap[urlHashValue]);
            }
        };

        window.addEventListener('hashchange', hashChangedHandler);

        return () =>
            window.removeEventListener('hashchange', hashChangedHandler);
    }, []);

    const initializeSharpstar = useCallback(async () => {
        const { default: SharpstarServiceHandler } = await import(
            'App/Services/Handlers/SharpstarServiceHandler'
        );

        return new SharpstarServiceHandler(
            sharpstarApiUrl,
            source,
            campaignId,
            new CookieService(domain)
        );
    }, [sharpstarApiUrl, source, campaignId, domain]);

    const initializeGameGA = useCallback(async () => {
        const { default: GameAnalyticsService } = await import(
            'App/Services/Analytics/GameAnalytics'
        );

        return new GameAnalyticsService(
            gameAnalyticsKey,
            gameAnalyticsSecret,
            environment(),
            buildVersion
        );
    }, [gameAnalyticsKey, gameAnalyticsSecret, buildVersion]);

    const initializeGA = useCallback(async () => {
        const { default: GoogleAnalytics } = await import(
            'App/Services/Analytics/GA4'
        );

        return new GoogleAnalytics(
            true,
            source,
            gaTrackId,
            {},
            { debug_mode: environment() !== 'production' }
        );
    }, [gaTrackId, source]);

    useEffect(() => {
        if (isPageLoaded) {
            SentryConfig.init(
                config.sentryEnabled,
                null,
                config.source,
                config.campaignId,
                null,
                document.location.host,
                environment(),
                urlParams.refid3
            );
            initializeSharpstar().then((sharpstarService) =>
                setSharpstarService(sharpstarService)
            );
        }
    }, [isPageLoaded]);

    useEffect(() => {
        if (isPageLoaded) {
            initializeGameGA().then((gameGAInstance) =>
                setGameGA(gameGAInstance)
            );
            initializeGA().then((gaInstance) => setGA(gaInstance));
        }
    }, [initializeGameGA, initializeGA, isPageLoaded]);

    useEffect(() => {
        if (offer) {
            const { popup, ...rest } = getGameInitValues(offer.content);
            const leadData = filterGameRelatedAttributes(offer.content);

            setLeadExtraData(leadData);

            const localAdProvider = localStorageValueReset(
                'adProvider',
                offer.content['ad-provider']
            );

            setAdsConfig({
                provider: localAdProvider || offer.content['ad-provider'],
                sdkSrc: {
                    vli: config.vliSrc,
                    adinplay: config.adinplaySrc,
                },
                rewardMap: rewardIAdMap,
            });
            setGameConfig({
                buildVersion: offer.content['build-version'],
                buildUrl: `${config.buildPath}${offer.content['build-version']}/`,
                popup: popup,
                main: { ...rest },
            });
        }
    }, [offer]);

    const checkIsAdBlocked = () => {
        return new Promise((resolve) => {
            checkAdBlockers((adsBlocked) => {
                setAdBlocked(adsBlocked);
                resolve(adsBlocked);
            });
        });
    };

    const handleUrlParams = () => {
        const urlParamsObj = getUrlParams(window.location.href, config.rid);

        setUrlParams(urlParamsObj);
    };

    const updatePhase = (name) => {
        setPhase(name);
        localStorage.setItem('phase', name);
    };

    const checkPhase = (showPushPhase, showPwaPhase) => {
        const urlHashValue = getHash();
        const phaseFromMap = phaseMap[urlHashValue];

        // If the current URL has a hash, update to this hash phase
        if (phaseFromMap) {
            updatePhase(phaseFromMap);
            return;
        }

        // Retrieve the phase from localStorage or default to 'landing'
        const storedPhase = localStorage.getItem('phase') || 'landing';
        const nextPhase =
            storedPhase === 'landing'
                ? 'landing'
                : showPushPhase
                  ? 'push'
                  : showPwaPhase
                    ? 'pwa'
                    : 'game';

        updatePhase(nextPhase);
    };

    return (
        <AppContext.Provider
            value={{
                offer,
                ga,
                gameGA,
                phase,
                adBlocked,
                urlParams,
                gameConfig,
                adsConfig,
                leadExtraData,
                setOffer,
                setGameConfig,
                setAdsConfig,
                sharpstarService,
                updatePhase,
                checkPhase,
                checkIsAdBlocked,
            }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);
