import { environment, isLocal } from 'App/Helpers/enviroments';

const env = environment();
let config;

const baseConfig = {
    buildVersion: '1.1.5.3',
    lang: 'en',
    isStage: true,
    adProvider: 'dev', // change to 'ad provider' name if ads need to be tested on dev otherwise keep 'dev' value
    sourceName: 'Snake',
    sentryEnabled: true,
    vliSrc: '//cdn.vlitag.com/w/c2fb798d-6385-4c5c-8e8f-de96e1b1da11.js',
    adinplaySrc:
        '//api.adinplay.com/libs/aiptag/pub/RYM/snakecolorbrake.com/tag.min.js',
    gameAnalyticsKey: '029b0f00b8b474fbcf7162baee12f5d4', // link to Game Analytics app - https://tool.gameanalytics.com/game/284674/
    gameAnalyticsSecret: '0553e0ead6253035c96d13e831c28e82735e5ece',
    modalsUrl: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=',
    terms: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=terms',
    privacy:
        'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=privacy-policy',
};

const local = {
    ...baseConfig,
    campaignId: 25,
    rid: 108,
    source: `${baseConfig.sourceName} Local`,
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    domain: 'http://127.0.0.1:8080/',
    buildPath: 'http://rm-snakecolorbrake-game/Build/',
    oneSignalAppId: 'cd43657e-9a63-4013-b24e-fa3486f748b0', // OneSignal dev app name: 'Localhost CY Development'
    gaTrackId: 'G-7QMGB80HXS', // Google Analytics app name: Games-Dev-ALL; stream name: Games Dev
};

const dev = {
    ...baseConfig,
    campaignId: 64,
    rid: 134,
    source: `${baseConfig.sourceName} Dev`,
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    domain: 'https://stage.snakecolorbrake.com/',
    buildPath: 'https://stage.snakecolorbrake.com/build/',
    oneSignalAppId: '63c4c5ca-30f5-47cb-8b2d-8bbb2fd00860', // OneSignal Stage App name: Development US Development
    safariWebId: 'web.onesignal.auto.184299bc-1c91-4dec-a5d4-b75cfd155372',
    gaTrackId: 'G-7QMGB80HXS', // Google Analytics app name: Games-Dev-ALL; stream name: Games Dev
};

const prod = {
    ...baseConfig,
    campaignId: 28,
    rid: 118,
    adProvider: 'vli',
    source: `${baseConfig.sourceName} Prod`,
    isStage: false,
    sharpstarApiUrl: 'https://dc.sharpstar.pro/',
    domain: 'https://snakecolorbrake.com/',
    buildPath: 'https://snakecolorbrake.com/game/',
    oneSignalAppId: 'a61cec45-edfe-4f0d-b896-78a3b003779a', // OneSignal prod app name: Games_web US Production
    safariWebId: 'web.onesignal.auto.5d56d362-2565-48e1-9c7d-b5c325eeeb04',
    gaTrackId: 'G-TW3JV6VRDL', // Google Analytics app name: Snake Color Break GA4; stream name: snakecolorbrake.com
    gameAnalyticsKey: '11e2d22cd4ccd6441751c221ade147f1', // link to Game Analytics app - https://tool.gameanalytics.com/game/248446/
    gameAnalyticsSecret: 'b60ab3b934993cad95037de83d3f317989212b26',
};

if (env === 'production') {
    config = prod;
} else if (env === 'development') {
    config = dev;
} else {
    config = local;
}

export const oneSignalConfig = {
    appId: config.oneSignalAppId,
    safari_web_id: config.safariWebId,
    serviceWorkerPath: isLocal()
        ? 'OneSignalSDKWorker.js'
        : 'push/OneSignalSDKWorker.js',
    serviceWorkerParam: {
        scope: isLocal() ? '/' : '/push/',
    },
    notifyButton: {
        enable: false,
    },
    welcomeNotification: {
        disable: true,
    },
    allowLocalhostAsSecureOrigin: isLocal(),
    autoRegister: false,
};

export const gameContainerBg = {
    'D9D3CE': '#F7F1EC',
    'FFBEBD': '#FFEEED',
    'AED9FF': '#C2EDFF',
    'FFFDA8': '#FFFEE5',
    '161414': '#2A2828',
    'FFFFFF': 'rgba(117, 8, 255, .05)',
    '31006F': '#440494',
};

export const adBannerMap = {
    vli: {
        wide: { left: 'pw_18501', right: 'pw_17217' },
        narrow: { left: 'pw_22509', right: 'pw_22508' },
        center: { hp: 'pw_17220', result: 'pw_17871', pause: 'pw_17869' },
        footer: {
            'hp': 'pw_17216',
            'gameplay': 'pw_17868',
            'result': 'pw_17872',
            'pause': 'pw_17870',
            'customization': 'pw_40320',
            'endless-result': 'pw_40846',
            'leaderboard': 'pw_40847',
        },
    },
    adinplay: {
        wide: {
            left: 'snakecolorbrake-com_300x600_left',
            right: 'snakecolorbrake-com_300x600_right',
        },
        narrow: {
            left: 'snakecolorbrake-com_160x600_left',
            right: 'snakecolorbrake-com_160x600_right',
        },
        center: {
            hp: 'snakecolorbrake-com_300x250_HP',
            result: 'snakecolorbrake-com_300x250_restart',
            pause: 'snakecolorbrake-com_300x250_pause',
        },
        footer: {
            'hp': 'snakecolorbrake-com_320x50_HP',
            'gameplay': 'snakecolorbrake-com_320x50_gameplay',
            'result': 'snakecolorbrake-com_320x50_restart',
            'pause': 'snakecolorbrake-com_320x50_pause',
            'customization': '',
            'endless-result': '',
            'leaderboard': '',
        },
    },
};

// reward ad id map for VLI ad provider
export const rewardIAdMap = {
    revive: 'pw_17238',
    speedDown: 'pw_18312',
    plus15: 'pw_18313',
    extraCoins: 'pw_18528',
    giftCoins: 'pw_23378',
    startRace: 'pw_39310',
};

export default config;
